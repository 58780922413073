<form [formGroup]="dashboardForm">
  <div class="div-26">
    <div class="div-27">
      <div class="div-28">
        <!-- <div class="div-29">Hi John Doe</div> -->
        <!-- <div class="div-30">Deals Dashboard</div> -->
        <div class="div-30"> {{placeHolders['dashboard_title']}}</div>
      </div>
      <div class="div-31">
        <div class="div-32" (click)="createCampaign()">{{placeHolders['LBL_CREATE_DEAL']}}</div>
        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/1a173d72-6ffb-4055-8028-ba2b294272a4?"
          class="img-13" />
      </div>
    </div>
    <div class="div-33" *ngIf="isNewUser" isNewUser>
      <mat-radio-group formControlName="dateFilter">
        <mat-radio-button value="2" (change)="lastWeekData()"> {{placeHolders['Filter_last_week']}}</mat-radio-button>
        <mat-radio-button value="3" (change)="last15DaysData()"> {{placeHolders['Filter_last_15']}}</mat-radio-button>
        <mat-radio-button value="4" (change)="lastMonthData()"> {{placeHolders['Filter_last_month']}}</mat-radio-button>
        <mat-radio-button value="5" (change)="last3MonthData()"> {{placeHolders['Filter_3_Month']}}</mat-radio-button>
        <mat-radio-button value="1" (change)="customClick()"> {{placeHolders['Filter_Custom']}}
          <!-- <mat-input-container>
          <input #date matInput [matDatepicker]="datePicker" placeholder="FFM"
          (dateInput)="addEvent(date)" (dateChange)="addEvent(date)">
          <mat-datepicker #datePicker></mat-datepicker>
      </mat-input-container> -->

        </mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="div-17" *ngIf="datePickerVisible===true">
      <div class="div-18">
        <!-- <span>From:</span> -->
        <div class="div-21">
          <label style="margin-left: -65px;font-weight: 500;">{{placeHolders['Date_From']}}:</label>
          <Input type="date" min="{{ minDate }}" max="{{ todaysDate }}" (change)="setFromDate($event)"
            onkeydown="return false" placeholder="Select date" style="border: 0px solid transparent;" />
        </div>
      </div>
      <div class="div-23">
        <div class="div-24">
          <!-- <div class="div-25">To</div> -->
          <img loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/4bbf3f6be6b4a0c4435de1895467800e2ddd5d0433d0ed53d3fd76dd73608aab?"
            class="img-9" />
        </div>
        <!-- <span>To:</span> -->
        <div class="div-1">
          <label style="margin-left: -30px;font-weight: 500;">{{placeHolders['Date_To']}}:</label>
          <Input type="date" min="{{ minDate }}" max="{{ todaysDate }}" (change)="setToDate($event)"
            onkeydown="return false" placeholder="Select date" style="border: 0px solid transparent;" />
        </div>
      </div>
      <div>
        <button (click)="customOkClick()" class="div-82 mt-2">{{placeHolders['btn_GO']}}</button>
      </div>
    </div>




    <div class="div-44 pt-1" *ngIf="data">
      <div class="div-45">
        <!-- <div class="column-3">
          <div class="div-46 ">
            <div class="px-3 col-12"
              style="font: 500 38px/48px Manrope, -apple-system, Roboto, Helvetica, sans-serif; background: none;">
              <input type="number" class="col-10" formControlName="totalCampaign" readonly />
            </div>
            <div class="" style="padding-top:15px;">
              Total deals
            </div>
          </div>
        </div> -->
        <div class="col-xl-4 col-md-6">
          <!-- <app-stat _ngcontent-ng-c1189031665="" _nghost-ng-c2813046590=""> -->
          <div>
            <div class="card card-animate">
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <div class="flex-grow-1 overflow-hidden">
                    <p class="text-uppercase fw-medium text-tile text-muted text-truncate mb-0">
                      {{placeHolders['LBL_TOTAL_DEALS']}} :</p>
                  </div>
                  <!-- <div _ngcontent-ng-c2813046590="" class="flex-shrink-0">
                      <h5 _ngcontent-ng-c2813046590="" class="fs-14 mb-0 text-danger"><i _ngcontent-ng-c2813046590=""
                          class="fs-13 align-middle ri-arrow-right-down-line"></i><span
                          _ngcontent-ng-c2813046590="">-</span>3.57 % </h5>
                    </div> -->
                </div>
                <div class="d-flex align-items-end justify-content-between dealtileMargin">
                  <div style="margin-left: 138px;">
                    <h4 class="fs-22 fw-semibold ff-secondary mb-4"><!----><span
                        class="counter-value">{{this.campaignTotalData?.TOTAL_DEALS}}</span><!----><!----></h4>
                    <!-- <a
                         href="javascript:void(0);" class="text-decoration-underline">View
                        all Merchants</a> -->
                  </div>
                  <div class="avatar-sm flex-shrink-0"><span _ngcontent-ng-c2813046590=""
                      class="avatar-title bg-info fs-3 rounded">
                      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                        <path fill="currentColor"
                          d="M5 22h14c1.103 0 2-.897 2-2V9a1 1 0 0 0-1-1h-3V7c0-2.757-2.243-5-5-5S7 4.243 7 7v1H4a1 1 0 0 0-1 1v11c0 1.103.897 2 2 2M9 7c0-1.654 1.346-3 3-3s3 1.346 3 3v1H9zm-4 3h2v2h2v-2h6v2h2v-2h2l.002 10H5z" />
                      </svg></span></div>
                </div>
              </div>
            </div>
          </div>
          <!-- </app-stat> -->
        </div>
        <!-- <div class="column-4">
          <div class="div-47">
            <div class="div-48 ">
              <img loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/84aa27aa-2663-433b-a3da-3a96b986d4b1?" class="" />
              <div class="div-49 col-12">{{currencyData?.currency}}
                <input type="number" style="background: none" formControlName="totalBudget" readonly />
              </div>
            </div>
            <div class="div-50 px-5">Total budget</div>
          </div>
        </div> -->
        <div class="col-xl-4 col-md-6">
          <!-- <app-stat _ngcontent-ng-c1189031665="" _nghost-ng-c2813046590=""> -->
          <div>
            <div class="card card-animate">
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <div class="flex-grow-1 overflow-hidden">
                    <p class="text-uppercase fw-medium text-tile text-muted text-truncate mb-0">
                      {{placeHolders['LBL_TOTAL_BUDGET']}} :</p>
                  </div>
                  <!-- <div _ngcontent-ng-c2813046590="" class="flex-shrink-0">
                      <h5 _ngcontent-ng-c2813046590="" class="fs-14 mb-0 text-danger"><i _ngcontent-ng-c2813046590=""
                          class="fs-13 align-middle ri-arrow-right-down-line"></i><span
                          _ngcontent-ng-c2813046590="">-</span>3.57 % </h5>
                    </div> -->
                </div>
                <div class="d-flex align-items-end justify-content-between dealtileMargin">
                  <div style="margin-left: 130px;">
                    <h4 class="fs-22 fw-semibold ff-secondary mb-4"><!----><span class="counter-value">{{currency}}
                        {{this.campaignTotalData?.TOTAL_BUDGET}}</span><!----><!----></h4>
                    <!-- <a {{currencyData?.currency}}
                         href="javascript:void(0);" class="text-decoration-underline">View
                        all Merchants</a> -->
                  </div>
                  <div class="avatar-sm flex-shrink-0"><span _ngcontent-ng-c2813046590=""
                      class="avatar-title bg-success fs-3 rounded">
                      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                        <path fill="currentColor"
                          d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2m0 18a8 8 0 1 1 8-8a8 8 0 0 1-8 8" />
                        <path fill="currentColor"
                          d="M12 11c-2 0-2-.63-2-1s.7-1 2-1s1.39.64 1.4 1h2A3 3 0 0 0 13 7.12V6h-2v1.09C9 7.42 8 8.71 8 10c0 1.12.52 3 4 3c2 0 2 .68 2 1s-.62 1-2 1c-1.84 0-2-.86-2-1H8c0 .92.66 2.55 3 2.92V18h2v-1.08c2-.34 3-1.63 3-2.92c0-1.12-.52-3-4-3" />
                      </svg></span></div>
                </div>
              </div>
            </div>
          </div>
          <!-- </app-stat> -->
        </div>
        <!-- <div class="column-5">
          <div class="div-51">
            <div class="div-52">
              <img loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/bc783050-ad98-42e0-b872-9e5d8f7c5de5?" class="" />
              <div class="div-53 col-12">{{currencyData?.currency}}
                <input type="number" class="col-12" style="background: none" formControlName="actualSpent" readonly />
              </div>
            </div>
            <div class="div-54 px-5">Actual spent</div>
          </div>
        </div> -->
        <div class="col-xl-4 col-md-6">
          <!-- <app-stat _ngcontent-ng-c1189031665="" _nghost-ng-c2813046590=""> -->
          <div>
            <div class="card card-animate">
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <div class="flex-grow-1 overflow-hidden">
                    <p class="text-uppercase text-tile fw-medium text-muted text-truncate mb-0">
                      {{placeHolders['LBL_ACTUAL_SPENT']}} :</p>
                  </div>
                  <!-- <div _ngcontent-ng-c2813046590="" class="flex-shrink-0">
                      <h5 _ngcontent-ng-c2813046590="" class="fs-14 mb-0 text-danger"><i _ngcontent-ng-c2813046590=""
                          class="fs-13 align-middle ri-arrow-right-down-line"></i><span
                          _ngcontent-ng-c2813046590="">-</span>3.57 % </h5>
                    </div> -->
                </div>
                <div class="d-flex align-items-end justify-content-between dealtileMargin">
                  <div style="margin-left: 160px;">
                    <h4 class="fs-22 fw-semibold ff-secondary mb-4"><!----><span class="counter-value">{{currency}}
                        {{this.campaignTotalData?.TOTAL_ACTUAL_SPENT}}</span><!----><!----></h4>
                    <!-- <a
                         href="javascript:void(0);" class="text-decoration-underline">View
                        all Merchants</a> -->
                  </div>
                  <div class="avatar-sm flex-shrink-0"><span _ngcontent-ng-c2813046590=""
                      class="avatar-title bg-danger fs-3 rounded">
                      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M16 12h2v4h-2z" />
                        <path fill="currentColor"
                          d="M20 7V5c0-1.103-.897-2-2-2H5C3.346 3 2 4.346 2 6v12c0 2.201 1.794 3 3 3h15c1.103 0 2-.897 2-2V9c0-1.103-.897-2-2-2M5 5h13v2H5a1.001 1.001 0 0 1 0-2m15 14H5.012C4.55 18.988 4 18.805 4 18V8.815c.314.113.647.185 1 .185h15z" />
                      </svg></span></div>
                </div>
              </div>
            </div>
          </div>
          <!-- </app-stat> -->
        </div>
      </div>
    </div>


    <!-- <div class="pt-2 div-17" style="display: flex; justify-content: end;">
      <div class="div-50 col-5 px-2" *ngIf="allCategory && allCategory.length !==0">
        <label style="color: black;font-weight:500;">Category</label> 
        <div>
          <ng-multiselect-dropdown [settings]="dropdownSettings" [placeholder]="'Select Category'" [data]="allCategory"
            bindLabel="category_type" bindValue="category_id" formControlName="category"
            (click)="setCategoryValue($event)">
          </ng-multiselect-dropdown>
        </div>
      </div>
      <div class="div-50 ">        
        <label style="color: black;font-weight:500;">Status</label> 
        <div>
          <mat-radio-group aria-label="Select an option" formControlName="status">
            <mat-radio-button value="All">All</mat-radio-button>
            <mat-radio-button value="A">Active</mat-radio-button>
            <mat-radio-button value="P">Paused</mat-radio-button>
            <mat-radio-button value="E">Expired</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      
    </div> -->
    <!-- <div>    
    <div class="pt-2" style="float: right;">
      <button class="div-82 px-2" (click) = "onSubmitFilter()" >Go</button>
      <button class="div-100 mx-1" (click) = "onResetFilter()">Reset</button>
    </div>
  </div> -->
    <div class="div-56">
      <div class="div-57 pt-3">{{placeHolders['LBL_ALL_DEALS']}}</div>
      <div class="div-58" *ngIf="isNewUser">
        <div class="div-59">
          <div class="div-60">
            <input type="search" (search)="search()" formControlName="search" autocomplete="off" maxlength="100"
              class="div-61" style="border:none; background-color: transparent ;"
              placeholder="{{placeHolders['LBL_SEARCH_PLACEHOLDER']}}" />
            <!-- <div class="div-61">Search</div> -->
            <img loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/960e3587b102d32ae26b055144ae7805805584175a9f8744d348d759c2ad8f5e?"
              class="img-22" (click)="search()" />
          </div>
        </div>
        <div class="div-62" (click)="filterCampaign()">
          <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 24 24">
            <path fill="none" stroke="#4f9806" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M22 3H2l8 9.46V19l4 2v-8.54z" />
          </svg>
          <!-- <img 
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/67410ecd28e542fc77d1ec58dd6b5b5bad9785f1a076cde8b71bcc022f28a5b1?"
            class="img-23" (click)="filterCampaign()" /> -->
        </div>
        <div>
          <svg (click)="reload()" xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 512 512">
            <path fill="#306e33"
              d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208s208-93.31 208-208S370.69 48 256 48m120 190.77h-89l36.88-36.88l-5.6-6.51a87.38 87.38 0 1 0-62.94 148a87.55 87.55 0 0 0 82.42-58.25l5.37-15.13l30.17 10.67l-5.3 15.13a119.4 119.4 0 1 1-112.62-159.18a118.34 118.34 0 0 1 86.36 36.95l.56.62l4.31 5L376 149.81Z" />
          </svg>
        </div>
      </div>
      <!-- <div *ngIf="data.length === 0" style="padding-top: 5px;">
        <svg (click)="last15DaysData()" style="cursor: pointer;" xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24">
          <g fill="none" stroke="#dc3545" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
            <path
              d="m21 21l-6-6M3.268 12.043A7.02 7.02 0 0 0 9.902 17a7.01 7.01 0 0 0 7.043-6.131a7 7 0 0 0-5.314-7.672A7.02 7.02 0 0 0 3.39 7.6" />
            <path d="M3 4v4h4" />
          </g>
        </svg>
      </div> -->
    </div>
    <div class="row pb-1" *ngIf="filterDataForChip">
      <span>
        <label *ngIf="filterDataForChip.status!==''">
          <b> Applied filters: </b>
        </label>
        <button (click)="clearAll()" *ngIf="filterDataForChip.status!==''" class="div-filterbtn">
          Clear all x</button>
        <label *ngIf="filterDataForChip.status!==''" class="div-chip">
          <b>Status</b>: {{filterDataForChip.status}} </label>
        <label class="div-chip" *ngIf="filterDataForChip.budget!==''">
          <b>Budget</b>: {{filterDataForChip.budget}}</label>
        <label class="div-chip" *ngIf="filterDataForChip.actualSpent!==''">
          <b>Actual spent</b>: {{filterDataForChip.actualSpent}}</label>
        <span *ngIf="filterDataForChip.category!==[]">
          @for (data of filterDataForChip.category; track data) {
          <label class="div-chip">{{data}}</label>
          }
        </span>
      </span>
    </div>
    <div class="div-55" *ngIf="data.length!==0">
      <!-- <div class="div-56"> -->
      <!-- <div
      class="grid-card-body"
      style="
        border: 1px solid #c4c4c4;
        border-radius: 0px 0px 5px 5px;
        margin-bottom: 20px;
      "  *ngIf="data.length!==0"
    > -->
      <ag-grid-angular style="width: 100%; " class="ag-theme-quartz" [rowData]="data" domLayout='autoHeight'
        [gridOptions]="gridOptions" [suppressDragLeaveHidesColumns]="true" [columnDefs]="columnDefs"
        [animateRows]="true" [pagination]="false" (gridReady)="onGridReady($event)">
      </ag-grid-angular>



      <!-- <div class="row">
      <div class="col-lg-8 col-md-10 ml-auto mr-auto">
        <span><strong>Current Active Page = </strong> {{activePage}}</span>
      </div>
    </div> -->





      <!-- <c-pagination >
      <li cPageItem>
        <a cPageLink [routerLink] style="background-color: white;color: black;">Previous</a>
      </li>
      <li cPageItem>
        <a cPageLink [routerLink] style="background-color: white;color: black;">1</a>
      </li>
      <li cPageItem>
        <a cPageLink [routerLink] style="background-color: white;color: black;">2</a>
      </li>
      <li cPageItem>
        <a cPageLink [routerLink] style="background-color: white;color: black;">3</a>
      </li>
      <li cPageItem>
        <a cPageLink [routerLink] style="background-color: white;color: black;">Next</a>
      </li>
    </c-pagination> -->
    </div>



    <div *ngIf="isNewUser; else noRecords">

      <div class="div-Norecord" *ngIf="data.length===0">
        <div class="div-Norecord2">
          <div class="div-Norecord3">No Record Found !!</div>
        </div>
      </div>
    </div>
    <ng-template #noRecords>
      <div class="div-Norecord" *ngIf="data.length===0">
        <div>
          <img style="height: 120px; width: 120px;" src="../../../assets/images/createcampaign.png">
          <div class="div-Norecord3" [ngClass]="createrecordtext()">{{placeHolders['LBL_NO_DEALS_CREATED']}}</div>
          <div class="div-centerlabel firsttimeRecord">
            <div class="div-createdeal" (click)="createCampaign()">{{placeHolders['LBL_CREATE_DEAL']}}</div>
            <img loading="lazy" [ngClass]="getDynamicClass()"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/1a173d72-6ffb-4055-8028-ba2b294272a4?"
              class="img-13" />
          </div>
        </div>
      </div>

    </ng-template>

    <div class="row mt-2">
      <div class="col-lg-12 col-md-12 ml-auto mr-auto">
        <div class="float-right">
          <app-pagination *ngIf="recordTotalCount!==0" [totalRecords]="recordTotalCount" [recordsPerPage]="10"
            [activePage]="activePage" (onPageChange)="displayActivePage($event)"></app-pagination>
        </div>
      </div>
    </div>



  </div>
</form>