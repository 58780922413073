
import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { PaymentService } from '../../services/payment.service';
import { Router } from '@angular/router';
import { NotificationAlertService } from 'src/app/services/notification-alert.service';
import { ClientSideStorageService } from 'src/app/services/client-side-storage.service';
import { environment } from 'src/environments/environment';

declare var Stripe: any;

@Component({
  selector: 'app-paymentcheckout',
  templateUrl: './paymentcheckout.component.html',
  styleUrl: './paymentcheckout.component.scss'
})
export class PaymentcheckoutComponent implements OnInit {
  @ViewChild('cardElement') cardElement!: ElementRef;
  @Input() amount!: number;
  @Input() currency: string = 'usd';
  @Input() description: string = 'Payment';
  @Input() brandId: any;
  @Input() type:any;
  captureForm: FormGroup;
  showCheckout:boolean = true;


  payUrl:any;
  paymentShow: boolean = false;
  stripe: any;
  card: any;
  paymentForm: FormGroup;
  checkoutForm: FormGroup;
  loading = false;
  errorMessage: string | null = null;
  paymentStatus: string | null = null;
  personID: any;
  paymentMethod:any;
  paymentIntetResponse:any;
  btnLabel:any;

  constructor(private fb: FormBuilder, private router: Router, private notifyService: NotificationAlertService
    , private http: HttpClient, private paymentService: PaymentService, private cStorage: ClientSideStorageService) {
    this.personID = JSON.parse(this.cStorage.get("userId"));
    this.paymentForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]]
    });
    this.captureForm = this.fb.group({
      amount: ['', Validators.required],
    });
    this.checkoutForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      cardNumber: ['', [Validators.required, Validators.pattern(/^[0-9]{16}$/)]],
      expiry: ['', [Validators.required, Validators.pattern(/^(0[1-9]|1[0-2])\/[0-9]{2}$/)]],
      cvc: ['', [Validators.required, Validators.pattern(/^[0-9]{3,4}$/)]],
      cardholderName: ['', Validators.required],
      country: ['US', Validators.required],
      zip: ['', [Validators.required, Validators.pattern(/^[0-9]{5}(?:-[0-9]{4})?$/)]]
    });
  }







  ngOnInit() {
     this.btnLabel =  this.type ==='campaign' ?'Authorize Payment' : 'Pay'
    this.initializeStripe();
  }

  pay() {
    this.initializeStripe();
  }
  //pk_test_51Pur3cRspVPo0MKWKaG7vUeURAvlNSEv2OiKFWMZwATBMwFu6Vvc7l1vb3mmcCWjQ0ZYenHBLxnGkKb2nxuhKKsT00IYOzJEnT
  //pk_test_51PyWIARsXoEstc81dXXD1IxdLsNLJiJPWzHjEYwsW9EXIkON6P1hmtpiCZrkAKKDkdZePV9uJDRaWYjxeVb5QCyJ00HWElayXR
  initializeStripe() {
    this.paymentShow = true;
    this.stripe = Stripe('pk_test_51Pur3cRspVPo0MKWKaG7vUeURAvlNSEv2OiKFWMZwATBMwFu6Vvc7l1vb3mmcCWjQ0ZYenHBLxnGkKb2nxuhKKsT00IYOzJEnT');
    const elements = this.stripe.elements();
    this.card = elements.create('card');

    // Wait for the component to render completely
    setTimeout(() => {
      this.card.mount(this.cardElement.nativeElement);
    });
  }

  async onSubmit() {
    if (this.paymentForm.valid) {
      this.loading = true;
      this.errorMessage = null;
      this.paymentStatus = null;

      const { paymentMethod, error } = await this.stripe.createPaymentMethod({
        type: 'card',
        card: this.card,
        billing_details: {
          name: this.paymentForm.get('name')?.value,
          email: this.paymentForm.get('email')?.value,
        }
      });

      if (paymentMethod) {
        // Store paymentMethod in a class variable for later use
        this.paymentMethod = paymentMethod;
        console.log('Payment method created:', paymentMethod);
      }

      if (error) {
        this.errorMessage = error.message;
        this.loading = false;
      } else {
        if(this.type === 'campaign'){
          this.payUrl = 'orchestration-service/admin/payment/authorize'
        this.http.post(environment.authUrl + this.payUrl + '?userId=' + this.personID, {
          paymentMethodId: paymentMethod.id,
          amount: this.amount,
          currency: this.currency,
          description: this.description,
          brandId: this.brandId,
          country: paymentMethod.card.country,
          display_brand: paymentMethod.card.display_brand,
          exp_month: paymentMethod.card.exp_month,
          exp_year: paymentMethod.card.exp_year,
          // funding: "credit",
          type: this.type,
          last4: paymentMethod.card.last4,
          billing_details: {
            name: this.paymentForm.get('name')?.value,
            email: this.paymentForm.get('email')?.value,
            postal_code:paymentMethod.billing_details.address.postal_code
          }
        }).subscribe(
          (response: any) => {
            if (response.statusCode === '200') {
              this.notifyService.showSuccess(
                "Payment Authorization Successful!",
                'Success'
              );
              this.paymentIntetResponse = response;
              this.showCheckout = false
              this.captureForm.get('amount')?.setValue(response.data.amount);
            }
            // this.paymentStatus = 'Payment Successful!';
            // this.paymentService.updateApiResponse(response);
            this.loading = false;
            // this.router.navigate(["/dashboard"]);
          },
          (error) => {
            this.notifyService.showError(
              "Payment failed!",
              'ERROR'
            );
            this.errorMessage = 'Payment failed: ' + error.error.message;
            this.loading = false;
          }
        );
        }
        else{

          this.payUrl = 'orchestration-service/admin/payment'
          this.http.post(environment.authUrl + this.payUrl + '?userId=' + this.personID, {
            paymentMethodId: paymentMethod.id,
            amount: this.amount,
            currency: this.currency,
            description: this.description,
            brandId: this.brandId,
            country: paymentMethod.card.country,
            display_brand: paymentMethod.card.display_brand,
            exp_month: paymentMethod.card.exp_month,
            exp_year: paymentMethod.card.exp_year,
            // funding: "credit",
            type: this.type,
            last4: paymentMethod.card.last4,
            billing_details: {
              name: this.paymentForm.get('name')?.value,
              email: this.paymentForm.get('email')?.value,
              postal_code:paymentMethod.billing_details.address.postal_code
            }
          }).subscribe(
            (response: any) => {
              if (response.statusCode === '200') {
                this.notifyService.showSuccess(
                  "Payment Successful!",
                  'Success'
                );
                // this.paymentIntetResponse = response;
                // this.showCheckout = false
                // this.captureForm.get('amount')?.setValue(response.data.amount);
              }
              // this.paymentStatus = 'Payment Successful!';
              this.paymentService.updateApiResponse(response);
              this.loading = false;
              // this.router.navigate(["/dashboard"]);
            },
            (error) => {
              this.notifyService.showError(
                "Payment failed!",
                'ERROR'
              );
              this.errorMessage = 'Payment failed: ' + error.error.message;
              this.loading = false;
            }
          );
        }
      }
    }
  }

  onCapturePayment(){
    this.http.post(environment.authUrl + 'orchestration-service/admin/payment/capture' + '?userId=' + this.personID, {
      paymentIntentId: this.paymentIntetResponse.data.paymentIntentId,
      amount: this.captureForm.get('amount')?.value,
      currency: this.currency,
      brandId: this.brandId,
    
     
    }).subscribe(
      (response: any) => {
        if (response.status === 'Success') {
          this.notifyService.showSuccess(
            "Payment Captured Successful!",
            'Success'
          );
        }

        // this.paymentStatus = 'Payment Successful!';
        this.paymentService.updateApiResponse(response);
        this.loading = false;
        // this.router.navigate(["/dashboard"]);
      },
      (error) => {
        this.notifyService.showError(
          "Payment failed!",
          'ERROR'
        );
        // this.errorMessage = 'Payment failed: ' + error.error.message;
        // this.loading = false;
      }
    );
  }
}