import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ClientSideStorageService } from './client-side-storage.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private allCategoryData = new BehaviorSubject<any>([]);
  private postOfficeData = new BehaviorSubject<any>(this.getStoredData());
  closeEvent: EventEmitter<any> = new EventEmitter();
  filterdata: any;
  userId: any;

  constructor(private http: HttpClient, private cStorage: ClientSideStorageService) {
    this.userId = JSON.parse(this.cStorage.get("userId"));
  }

  emit(data: any) {
    this.closeEvent.emit(data);
  }

  getCloseEvent() {
    return this.closeEvent;
  }

  getBrandSpecificDeals(data: any, brandId:any): Observable<any> {
    this.userId = JSON.parse(this.cStorage.get("userId"));
    let payload: any = {
      categories: []
    }
    if (data.categories?.length === 0) {
      payload.categories = [];
    } else {
      payload.categories = data.categories;
    }
    let url = environment.authUrl+'orchestration-service/admin/brand/deals?' + 'userId=' + this.userId + '&brandId='+ brandId + '&status=' + data.filterStatus + '&budget=' + data.filterbudget + '&spent=' + data.filterSpent + '&from=' + data.fromDate + '&to=' + data.toDate + '&pageNo=' + data.pagination + '&pageSize=10' + '&search=' + data.search;

    // if (status === "All") {
    //   url = 'https://dev.ship2myid.com/orchestration-service/admin/brand/deals?' + 'userId=' + this.userId + '&brandId=4' + '&budget=&spent=' + '&from=' + fromDate + '&to=' + toDate + '&pageNo=' + pageNumber + '&pageSize=10' ;

    // } else if (status === undefined || status === null) {
    //   status = "A";
    //   // const url = 'https://dev.ship2myid.com/orchestration-service/admin/brand/deals?userId=25772&brandId=4&status=A&from=11/18/2022&to=11/28/2023&pageNo=1&pageSize=10';
    //   //  https://dev.ship2myid.com/orchestration-service/admin/brand/deals?userId=25772&brandId=4&status=A&budget=&spent=&from=11/18/2022&to=11/28/2023&pageNo=1&pageSize=10&search=<search name>
    //   url = 'https://dev.ship2myid.com/orchestration-service/admin/brand/deals?' + 'userId=' + this.userId + '&brandId=4' + '&status=' + status + '&budget=&spent=' + '&from=' + fromDate + '&to=' + toDate + '&pageNo=' + pageNumber + '&pageSize=10' + '&search=';
    //   url = 'https://dev.ship2myid.com/orchestration-service/admin/brand/deals?userId=25772&brandId=4&status=A&budget=&spent=&from=11/18/2022&to=11/28/2023&pageNo=1&pageSize=10&search=';
    // } else {
    //   url = 'https://dev.ship2myid.com/orchestration-service/admin/brand/deals?userId=25772&brandId=4&status=A&budget=&spent=&from=11/18/2022&to=11/28/2023&pageNo=1&pageSize=10&search=';
    //   //'https://dev.ship2myid.com/orchestration-service/admin/brand/deals?' + 'userId=' + this.userId + '&brandId=4' + '&status=' + status + '&budget=&spent=' + '&from=' + fromDate + '&to=' + toDate + '&pageNo=' + pageNumber + '&pageSize=10' + '&search=' ;
    // }
    return this.http.post(url, payload, { observe: 'response' });
  }

  getCategory(): Observable<any> {
    const translations = localStorage.getItem('userLocale');
    const url = translations === 'fr'?environment.authUrl+'orchestration-service/admin/category/all'+'?locale=FR': environment.authUrl+'orchestration-service/admin/category/all';
    return this.http.get(url, { observe: 'response' });
  }

  setPostOfficeData(param: any): void {
    localStorage.setItem('postOfficeData', JSON.stringify(param));
    this.postOfficeData.next(param);
  }

  private getStoredData(): any {
    const data = localStorage.getItem('postOfficeData');
    return data ? JSON.parse(data) : null;
  }

  getPostOfficeData(): Observable<any> {
    return this.postOfficeData.asObservable();
  }

  getPendingDealsData(payload:any): Observable<any> {
    const url = environment.authUrl+'orchestration-service/getDealApprovalLinksDetails';
    return this.http.post(url,payload, { observe: 'response' });
  }

  getPostOfficeDealsData(): Observable<any> {
    const url = environment.authUrl+'orchestration-service/getDealApprovalLinks';
    return this.http.get(url, { observe: 'response' });
  }

  approveDeals(payload:any): Observable<any> {
    const url = environment.authUrl+'orchestration-service/updateDealApprovalStatus';
    return this.http.post(url,payload, { observe: 'response' });
  }
 
  getBrandCategory(brandId:any): Observable<any> {
    const translations = localStorage.getItem('userLocale');
    const url = translations === 'fr'? environment.authUrl+'orchestration-service/admin/category/brand'+'?brandId='+ brandId +'&locale=FR' :environment.authUrl+'orchestration-service/admin/category/brand'+'?brandId='+ brandId;
    return this.http.get(url, { observe: 'response' });
  }

  setData(param: any): void {
    this.allCategoryData.next(param);
  }

  getData(): Observable<any> {
    return this.allCategoryData.asObservable();
  }

  campaignData(fromDate: any, toDate: any, brandId:any) {
    this.userId = JSON.parse(this.cStorage.get("userId"));
    const url = environment.authUrl+'orchestration-service/admin/deals/dashboard-metadata?userId=' + this.userId + '&brandId='+ brandId + '&from=' + fromDate + '&to=' + toDate;
    return this.http.get(url, { observe: 'response' });

  }
}

